<template >
  <div class="body">
	  <navTop></navTop>
    <div class="wrap">
      <div class="wrap-left">
        <menuLeft :active="menuIndex"></menuLeft>
      </div>
      <div class="wrap-right">
        <div class="recharge-form">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" hide-required-asterisk="">
				
			  <el-form-item :label="$t('customDesign.rechargeAmount')" prop="amount">
			    <el-input v-model="ruleForm.amount"></el-input>
			  </el-form-item>
			  <el-form-item prop="pay">
				  <el-radio-group v-model="ruleForm.pay">
					  <el-tabs style="width:420px">
						<el-tab-pane>
						  <span slot="label"><el-radio :label="1">微信icon</el-radio></span>
						</el-tab-pane>
						<el-tab-pane>
						  <span slot="label"><el-radio :label="2">支付宝icon</el-radio></span>
						</el-tab-pane>
						<el-tab-pane>
						  <span slot="label"><el-radio :label="3">银行卡支付icon</el-radio></span>
						  <div style="margin: 20px 0px;">
						  <el-form-item :label="$t('customDesign.cardNumber')" prop="cardNumber" style="margin-bottom:20px;">
							<el-input v-model="ruleForm.cardNumber"></el-input>
						  </el-form-item>
						  <el-form-item :label="$t('customDesign.cardPwd')" prop="cardPws">
							<el-input v-model="ruleForm.cardPwd"></el-input>
						  </el-form-item>
						  </div>
						</el-tab-pane>
					  </el-tabs>
				  </el-radio-group>
			  </el-form-item>
			  <el-form-item>
			    <el-button type="primary" @click="submitForm('ruleForm')" style="width: 100%;">{{$t("customDesign.confirmPay")}}</el-button>
			  </el-form-item>
			  
			</el-form>
		</div>
         
      </div>
    </div>
  </div>
</template>
<script>

import menuLeft from "@/components/MenuLeft";
import navTop from "@/components/navTop";
export default {
	components: {menuLeft,navTop},
	data() {
		return {
			menuIndex:2,
			ruleForm: {
			  amount: '',
			  cardNumber:'',
			  cardPws:'',
			  pay: 1,
			  
			},
			rules: {
			  amount: [
				{ required: true, message: this.$t("customDesign.mesRechargeAmount"), trigger: 'blur' },
				{ type: 'number', message: this.$t("customDesign.mesAmountNumber")}
			  ],
			  cardNumber: [
			  				{ required: true, message: this.$t("customDesign.mesCardNumber"), trigger: 'blur' },
			  ],
			  cardPws: [
			  				{ required: true, message: this.$t("customDesign.mesCardPwd"), trigger: 'blur' },
			  ],
			  pay: [
				{ required: true, message: this.$t("customDesign.mesPay"), trigger: 'change' }
			  ],
			  
			},
			
		}
	},
	methods: {
		handleClick(tab, event) {
			console.log(tab, event);
		}
	}
}
</script>
<style  scoped>
.body {}

.wrap {
  margin: 0 auto;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.wrap-left {
  font-size: 14px;
  width: 20%;
  min-height: 800px;
  background-color: #fff;
  padding: 20px 15px 40px;
}

.wrap-right {
  width: 78%;
  min-height: 800px;
  background-color: #fff;
}

.recharge-form{
	width:600px;
	padding:40px;
}
</style>
