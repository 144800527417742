<template>
	<el-row class="tac">
	  <el-col :span="13">
			<el-menu :default-active="active" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" style="border:none;width:200px;text-align: center;">
				<el-menu-item :index="index.toString()" v-for="(item,index) in nav" v-if="item.submenu.length == 0" :key="`nav-${index}`">
					<i :class="item.icon"></i>
					<span slot="title" @click="navTo(item.link)" class="title-font">{{ $lang(item, "text") }}</span>
				</el-menu-item>
				<el-submenu :index="index.toString()"  v-else>
					
						<i :class="item.icon"></i>
						<span slot="title"><el-link @click="navTo(item.link)" class="title-font" :class="active==1?'active':''" :underline="false" >{{ $lang(item, "text") }}</el-link></span>
					
					<el-menu-item-group v-for="(group,k) in item.submenu" :key="k">
						<el-menu-item :index="((k+2)*10).toString()"><el-link @click="changeStatus(group.status)">{{$lang(group.orderStatus,"status")}}</el-link></el-menu-item>
					</el-menu-item-group>
				</el-submenu>
			</el-menu>
	  </el-col>
	</el-row>
</template>
<script>
	export default {
		props:{
			active:{},
		},
		data() {
			
			return {
				
				nav: [
					{
					  icon: '',
					  textZhCn: "会员中心",
					  textEnUs: "UserCenter",
					  submenu: [],
					  link:"/userhome/usercenter"
					},
					{
					  icon: '',
					  textZhCn: "订单中心",
					  textEnUs: "OrderCenter",
					  link:"/userhome/ordercenter",
					  submenu: [
						{
							link:'/userhome/ordercenter?orderStatus=3',
							orderStatus:{
								statusZhCn:'已完成',
								statusEnUs:"over"
							},
							orderTotal: 12,
							status:3,
						},
						{
							link:'/userhome/ordercenter?orderStatus=2',
						    orderStatus:{
							    statusZhCn:'待发货',
							    statusEnUs:"delive"
						    },
						   orderTotal: 12,
						   status:2,
						},
						{
							link:'/userhome/ordercenter?orderStatus=1',
						    orderStatus:{
							    statusZhCn:'待收货',
							    statusEnUs:"received"
						    },
							orderTotal: 12,
							status:1,
						},
					  ]
					},
					{
					  icon: '',
					  textZhCn: "自定义设计点",
					  textEnUs: "Design",
					  link:"/userhome/customdesign",
					  submenu: []
					},
					{
					  icon: '',
					  textZhCn: "资金明细",
					  textEnUs: "CapitalDetial",
					  link:"/userhome/capital",
					  submenu: []
					},
					{
					  icon: '',
					  textZhCn: "申请开发票",
					  textEnUs: "Invoice",
					  link:"/userhome/applyinvoice",
					  submenu: []
					},
					{
					  icon: '',
					  textZhCn: "会员服务",
					  textEnUs: "UserService",
					  link:"/userhome/userservice",
					  submenu: []
					},
				],
			}
		},
		mounted() {
			
			console.log(this.active)
		},
		methods:{
			handleOpen(key, keyPath) {
				//console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				//console.log(key, keyPath);
			},
			navTo(url,params = null){
				
				if(url,params!= null){
					this.$router.push({ path: url, query: { orderStatus:params }});
				}else{
					this.$router.push(url);
				}
			},
			changeStatus(status){
				
				this.$emit('changeOrderStatus',status)//传递参数
			}
		}
	}
</script>

<style scoped>
	.title-font{
		font-size: 16px;
		font-weight: 400;
	}
	.is-active{
		color: #000;
		
	}
	.is-active span{
		font-weight: 600;
	}
	.active{
		color:#000000;
		font-weight: 600;
	}
</style>
